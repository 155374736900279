import { Button, Link, SxProps } from 'rebass';
import React, { useEffect, ReactChildren } from 'react';

import { default as analytics } from '@segment/analytics.js-core';

declare global {
  interface Window {
    analytics?: typeof analytics;
  }
}
// TrackingLink creates a Link or Button that intercepts clicks and make a call to segment with an event before navigating.
export function TrackingLink({
  Component = Link,
  event,
  eventProperties,
  children,
  ...props
}: {
  event: string;
  Component: Button | Link; // element name
  eventProperties?: { [key: string]: any };
  href: string;
  target?: string;
  rel?: string;
  children?: ReactChildren;
  onClick?: () => any;
} & SxProps) {
  const ref = React.useRef();

  if (typeof window !== `undefined`) {
    // Don't run this in SSR.
    useEffect(() => {
      if (ref.current && window.analytics && window.analytics.trackLink) {
        window.analytics.trackLink(ref.current, event, eventProperties);
      }
    }, [ref.current, window.analytics]);
  }

  return (
    <Component ref={ref} {...props}>
      {children}
    </Component>
  );
}
