import { Box, Flex, Text } from 'rebass';
import { navigate } from 'gatsby';
import { useLocation, WindowLocation } from '@reach/router';
import React, { useState } from 'react';
import ReactModal from 'react-modal';

import { InternalLink, ExternalLink } from './link';
import { NAVBAR_HEIGHT } from './utils';
import { NavCTAButton } from './button';
import { eligibilityEmailPath } from './eligibility/email';
import { lockScroll, unlockScroll } from './overlay';
import { smartNav } from './eligibility/utils';
// @ts-ignore
import CloseIcon from '../assets/svgs/Close.svg';
// @ts-ignore
import NavOpen from '../assets/svgs/NavCatOpen.svg';
// @ts-ignore
import NavClose from '../assets/svgs/NavCatClose.svg';
// @ts-ignore
import GridLogo from '../assets/svgs/GridLogo.svg';
// @ts-ignore
import GridLogoLight from '../assets/svgs/GridLogoLight.svg';
// @ts-ignore
import GridLogoBlack from '../assets/svgs/GridLogoBlack.svg';
// @ts-ignore
import HamburgerIcon from '../assets/svgs/HamburgerIcon.svg';
// @ts-ignore
import HamburgerIconLight from '../assets/svgs/HamburgerIconLight.svg';
// @ts-ignore
import LightningBolt from '../assets/svgs/LightningBolt.svg';

import Wrapper from './wrapper';
import colors from './themes/colors';
import { isIOS, isAndroid } from 'react-device-detect';
import CTA from './cta';

import { APP_STORE_ID, APP_DOWNLOAD_ANDROID } from './appStoreButtons';

export enum NavbarStyle {
  Primary,
  Light,
  Transparent,
  Dropshadow,
  Dark,
  Footer,
  BoostCardWaitlist,
  Neon,
}

const navbarStyles = {
  primary: {
    backgroundColor: 'teal300',
    color: 'white',
  },
  light: {
    backgroundColor: 'white',
    color: 'black',
  },
  transparent: {
    backgroundColor: 'transparent',
    color: 'white',
  },
  dropshadow: {
    backgroundColor: 'white',
    color: 'black',
  },
  dark: {
    backgroundColor: colors.black,
    color: 'white',
  },
  footer: {
    backgroundColor: colors.footerBlack,
    color: 'white',
  },
  boostCardwaitlist: {
    backgroundColor: 'teal300',
    color: 'white',
  },
  neon: {
    backgroundColor: 'white',
    color: 'black',
  },
};

interface LogoProps {
  style: NavbarStyle;
  onClick: () => void;
}

function Logo({ style, onClick }: LogoProps) {
  switch (style) {
    case NavbarStyle.Light:
    case NavbarStyle.Dropshadow:
      return <GridLogoLight onClick={onClick} />;
    case NavbarStyle.Primary:
      return <GridLogo onClick={onClick} />;
    case NavbarStyle.BoostCardWaitlist:
    case NavbarStyle.Dark:
      return <GridLogo onClick={onClick} />;
    case NavbarStyle.Footer:
      return <GridLogo onClick={onClick} />;
    case NavbarStyle.Neon:
      return <GridLogoBlack css={{ cursor: 'pointer' }} onClick={onClick} />;
    case NavbarStyle.Transparent:
      return <GridLogo onClick={onClick} />;
  }
}

function Hamburger({ style, onClick }: LogoProps) {
  switch (style) {
    case NavbarStyle.Primary:
    case NavbarStyle.Transparent:
    case NavbarStyle.Dark:
      return <HamburgerIcon onClick={onClick} />;
    case NavbarStyle.Neon:
      return <HamburgerIconLight onClick={onClick} />;
    case NavbarStyle.Footer:
      return <HamburgerIcon onClick={onClick} />;
    case NavbarStyle.Light:
    case NavbarStyle.Dropshadow:
      return <HamburgerIconLight onClick={onClick} />;
    case NavbarStyle.BoostCardWaitlist:
      return null;
  }
}

function NavCTA({ ctaStyle, zIndex, navbarStyle }) {
  switch (ctaStyle) {
    case 'NO_CTA':
      return null;
    case 'BOOST_CARD_WAITLIST':
      return (
        <Box ml={3} mb={8} sx={{ zIndex: zIndex }}>
          <CTA ctaStyle="VIRAL_LOOPS_BOOST_CARD" context="HERO" legal="" />
        </Box>
      );
    case 'UPDATED_LANDING_PAGE':
      return <NavbarCTAButton navbarStyle={navbarStyle} />;
    case 'DOWNLOAD_BUTTON': // fallthrough
    default:
      return (
        <Box ml={3} mb={8} sx={{ zIndex: zIndex }}>
          <NavbarCTAButton navbarStyle={navbarStyle} />
        </Box>
      );
  }
}

interface LocationState {
  overlay: { visible: boolean };
}

function Navbar({
  menu,
  currentPath,
  fixed,
  navbarStyle,
  ctaStyle,
  hidden,
  isLegacy,
}) {
  const [navOpen, setNavOpen] = useState(false);
  const location = useLocation() as WindowLocation<LocationState>;
  const zIndex = location?.state?.overlay?.visible ? 0 : 9999;
  let fixedOverride = fixed;
  if (navOpen) {
    lockScroll();
    fixedOverride = true;
  } else {
    unlockScroll();
    fixedOverride = fixed;
  }
  let styles: { color: string; backgroundColor: string };
  switch (navbarStyle) {
    case NavbarStyle.Primary:
      styles = navbarStyles.primary;
      break;
    case NavbarStyle.Light:
      styles = navbarStyles.light;
      break;
    case NavbarStyle.Transparent:
      styles = navbarStyles.transparent;
      break;
    case NavbarStyle.Dropshadow:
      styles = navbarStyles.dropshadow;
    case NavbarStyle.Dark:
      styles = navbarStyles.dark;
      break;
    case NavbarStyle.Footer:
      styles = navbarStyles.footer;
      break;
    case NavbarStyle.BoostCardWaitlist:
      styles = navbarStyles.boostCardwaitlist;
      break;
  }
  if (navOpen) {
    navbarStyle = NavbarStyle.Footer;
    styles = navbarStyles.footer;
  }

  if (fixedOverride) {
    navbarStyle = NavbarStyle.Dropshadow;
    styles = navbarStyles.dropshadow;
  }
  if (!fixed && navOpen) {
    (navbarStyle = NavbarStyle.Footer), (styles = navbarStyles.footer);
  }
  if (!isLegacy) {
    navbarStyle = NavbarStyle.Neon;
    styles = navbarStyles.neon;
  }

  var boxShadowVal = 'none';
  if (window !== undefined) {
    if (
      navbarStyle === NavbarStyle.Dropshadow &&
      window.pageYOffset > NAVBAR_HEIGHT
    ) {
      boxShadowVal = '0px 4px 11px rgba(0, 0, 0, 0.06)';
    }
  }

  const isLegalDocumentPage =
    (currentPath.includes('/terms/') || currentPath.includes('/privacy')) &&
    !isLegacy;

  return (
    <>
      <Wrapper
        left={0}
        isLegacy={isLegacy}
        marginX={'auto'}
        mb={navbarStyle === NavbarStyle.Transparent ? -NAVBAR_HEIGHT : 0}
        sx={{
          top: hidden ? '-110px' : isLegacy ? 0 : '50px',
          position: isLegalDocumentPage
            ? 'absolute'
            : fixedOverride
            ? 'fixed'
            : 'static',
          zIndex: zIndex,
          backgroundColor: isLegacy ? styles.backgroundColor : 'transparent',
          transition: 'top 100ms',
          boxShadow: boxShadowVal,
        }}
      >
        <Flex>
          <Flex
            as="nav"
            justifyContent="space-between"
            alignItems="baseline"
            height={isLegacy ? NAVBAR_HEIGHT : ['55px', '55px', '55px']}
            top={hidden ? '-110px' : 0}
            left={0}
            width="100%"
            mx={!isLegacy && [23, 75, 125]}
            pt={isLegacy && 3}
            pb={isLegacy && 3}
            sx={{
              borderRadius: !isLegacy && ['22.5px', '17.5px', '17.5px'],
              backgroundColor: styles.backgroundColor,
              color: styles.color,
            }}
          >
            <Flex
              ml={!isLegacy && '10px'}
              mt={'auto'}
              mb={'auto'}
              sx={{ zIndex: zIndex }}
            >
              <Logo
                style={navbarStyle}
                onClick={() => {
                  setNavOpen(false);
                  navigate('/');
                }}
              />
            </Flex>
            <Flex my={isLegacy && 'auto'} height={!isLegacy && '100%'}>
              <Flex>
                <Flex alignItems={'middle'}>
                  <Flex alignItems={!isLegacy && 'center'}>
                    {((menu && menu.links) || []).map(
                      ({ text, url, page, _type, _key, items, name }) => {
                        switch (_type) {
                          case 'internalLink':
                            return (
                              <Flex key={_key} flexDirection="column">
                                <NavInternalLink
                                  variant={
                                    isLegacy ? 'h500Medium' : 'intNavLink'
                                  }
                                  text={text}
                                  to={page.slug.current}
                                  display={['none', 'block', 'block']}
                                  sx={{ zIndex: zIndex }}
                                  color="inherit"
                                />
                                {isLegacy && (
                                  <Box
                                    height={3}
                                    marginX={16}
                                    backgroundColor="teal300"
                                    sx={{
                                      visibility: currentPath.startsWith(
                                        page.slug.current,
                                      )
                                        ? 'visible'
                                        : 'hidden',
                                      zIndex: zIndex,
                                    }}
                                  />
                                )}
                              </Flex>
                            );
                          case 'externalLink':
                            return (
                              <Flex key={_key} flexDirection="column">
                                <NavExternalLink
                                  variant={
                                    isLegacy ? 'h500Medium' : 'intNavLink'
                                  }
                                  text={text}
                                  to={url}
                                  display={['none', 'block', 'block']}
                                  sx={{ zIndex: zIndex }}
                                  color="inherit"
                                />
                                {/* External links will never have a highlighted state */}
                              </Flex>
                            );
                          case 'category':
                            return (
                              <Flex
                                key={_key}
                                flexDirection="column"
                                justifyContent={
                                  isLegacy ? 'flex-start' : 'center'
                                }
                                height="100%"
                                sx={{
                                  position: 'relative',
                                  '&:hover .dropdown-content': {
                                    height: [0, 125, 175],
                                  },
                                }}
                              >
                                <Text
                                  fontSize={['13px', '13px', '18px']}
                                  fontFamily={isLegacy ? 'body' : 'heading2'}
                                  p={['14px 16px', '14px 16px', '8px 16px']}
                                  display={['none', 'block', 'block']}
                                  sx={{
                                    position: 'relative',
                                    cursor: 'pointer',
                                    whiteSpace: 'nowrap',
                                    '&:hover': {
                                      color: colors.pale300,
                                    },
                                  }}
                                >
                                  {name}
                                </Text>
                                <Flex
                                  className="dropdown-content"
                                  flexDirection="column"
                                  overflow="hidden"
                                  alignItems="center"
                                  height={0}
                                  width="100%"
                                  bg="white"
                                  justifyContent="space-evenly"
                                  sx={{
                                    borderBottomLeftRadius: [0, 12.5, 15],
                                    borderBottomRightRadius: [0, 12.5, 15],
                                    position: 'absolute',
                                    top: '100%',
                                    transition: 'height 0.3s ease-in-out',
                                    transitionDelay: '0.1s',
                                  }}
                                >
                                  {items &&
                                    items.map(
                                      ({ text, url, page, _type, _key }) => {
                                        switch (_type) {
                                          case 'internalLink':
                                            return (
                                              <Flex key={_key}>
                                                <InternalLink
                                                  variant="intNavLink"
                                                  to={page.slug.current}
                                                  display={[
                                                    'none',
                                                    'block',
                                                    'block',
                                                  ]}
                                                  sx={{
                                                    fontFamily: 'heading2',
                                                    fontSize: [
                                                      '13px',
                                                      '13px',
                                                      '18px',
                                                    ],
                                                    textDecoration: 'inherit',
                                                    color: 'inherit',
                                                    cursor: 'pointer',
                                                    whiteSpace: 'nowrap',
                                                    ':hover': {
                                                      color: colors.pale300,
                                                    },
                                                  }}
                                                  activeStyle={{
                                                    fontWeight: 'bold',
                                                  }}
                                                >
                                                  {text}
                                                </InternalLink>
                                              </Flex>
                                            );
                                          case 'externalLink':
                                            return (
                                              <Flex key={_key}>
                                                <ExternalLink
                                                  variant="intNavLink"
                                                  to={url}
                                                  display={[
                                                    'none',
                                                    'block',
                                                    'block',
                                                  ]}
                                                  sx={{
                                                    fontFamily: 'heading2',
                                                    fontSize: [
                                                      '13px',
                                                      '13px',
                                                      '18px',
                                                    ],
                                                    cursor: 'pointer',
                                                    whiteSpace: 'nowrap',
                                                    padding: [
                                                      '14px 16px',
                                                      '14px 16px',
                                                      '8px 16px',
                                                    ],
                                                    ':hover': {
                                                      color: colors.pale300,
                                                    },
                                                  }}
                                                  activeStyle={{
                                                    fontWeight: 'bold',
                                                  }}
                                                >
                                                  {text}
                                                </ExternalLink>
                                              </Flex>
                                            );
                                          default:
                                            return null;
                                        }
                                      },
                                    )}
                                </Flex>
                              </Flex>
                            );
                          default:
                            return null;
                        }
                      },
                    )}
                  </Flex>
                </Flex>
                {isLegacy && (
                  <>
                    <NavCTA
                      ctaStyle={ctaStyle}
                      zIndex={zIndex}
                      navbarStyle={navbarStyle}
                    />
                    <Box
                      my="auto"
                      ml={['60px', '60px', '30px']}
                      display={
                        ctaStyle === 'BOOST_CARD_WAITLIST'
                          ? 'none'
                          : ['block', 'block', 'none']
                      }
                      sx={{ zIndex: zIndex }}
                    >
                      <Hamburger
                        style={navbarStyle}
                        onClick={() => {
                          setNavOpen(!navOpen);
                        }}
                      />
                    </Box>
                  </>
                )}
              </Flex>
            </Flex>
            {!isLegacy && (
              <Flex my={'auto'} mr={['5%', '5%', '10px']}>
                <NavCTA
                  ctaStyle={ctaStyle}
                  zIndex={zIndex}
                  navbarStyle={navbarStyle}
                />
                <Flex
                  my={'auto'}
                  ml={['15px', '15px', '30px']}
                  display={
                    ctaStyle === 'BOOST_CARD_WAITLIST'
                      ? 'none'
                      : ['block', 'none', 'none']
                  }
                  sx={{ zIndex: zIndex }}
                >
                  <Hamburger
                    style={navbarStyle}
                    onClick={() => {
                      setNavOpen(!navOpen);
                    }}
                  />
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Wrapper>
      {/* This box prevents background jump on mobile nav toggle if the navbar was originally static */}
      <Box
        height={NAVBAR_HEIGHT}
        display={!fixed && navOpen ? 'block' : 'none'}
      />
      <ReactModal
        style={{
          borderRadius: 0,
          overlay: {
            top: isLegacy ? NAVBAR_HEIGHT : 0,
            left: 0,
            borderRadius: 0,
          },
          content: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: 'none',
            background: colors.footerBlack,
            outline: 'none',
            padding: '45px 25px',
            borderRadius: 0,
          },
        }}
        isOpen={navOpen}
      >
        <Wrapper>
          <Flex flexDirection="column">
            <Box marginLeft="auto">
              <CloseIcon
                onClick={() => {
                  setNavOpen(false);
                }}
              />
            </Box>
            <Flex my={['5px', '5px', '15px']}>
              <Box
                width={3}
                height={50}
                backgroundColor={colors.neon}
                sx={{
                  visibility: currentPath === '/' ? 'visible' : 'hidden',
                  zIndex,
                }}
              />

              <NavInternalLink
                variant={'intNavLink'}
                text={'Home'}
                to={'/'}
                display="block"
                color={currentPath === '/' ? colors.neon : colors.white}
                sx={{
                  zIndex,
                  fontSize: ['22px', '22px', '32px'],
                }}
              />
            </Flex>
            {((menu && menu.links) || []).map(
              ({ text, url, page, _type, _key, items, name }) => {
                switch (_type) {
                  case 'internalLink':
                    return (
                      <MobileNavInternalLink
                        text={text}
                        key={_key}
                        _key={_key}
                        page={page}
                        currentPath={currentPath}
                        zIndex={zIndex}
                      />
                    );
                  case 'externalLink':
                    return (
                      <MobileNavExternalLink
                        text={text}
                        key={_key}
                        _key={_key}
                        url={url}
                        currentPath={currentPath}
                        zIndex={zIndex}
                      />
                    );
                  case 'category':
                    const [categoryOpen, setCategoryOpen] = useState(false);
                    return (
                      <Flex key={_key} my={['5px', '5px', '15px']}>
                        <Box
                          width={3}
                          height={50}
                          backgroundColor={colors.neon}
                          sx={{
                            visibility: 'hidden',
                            zIndex,
                          }}
                        />
                        <Flex flexDirection="column" width="100%">
                          <Flex
                            width="100%"
                            justifyContent="space-between"
                            onClick={() => setCategoryOpen(!categoryOpen)}
                          >
                            <Text
                              display="block"
                              sx={{
                                fontFamily: 'heading2',
                                fontSize: ['22px', '22px', '32px'],
                                textDecoration: 'inherit',
                                color: colors.white,
                                cursor: 'pointer',
                                whiteSpace: 'nowrap',
                                padding: ['14px 16px', '14px 16px', '8px 16px'],
                                ':hover': {
                                  color: colors.pale300,
                                },
                              }}
                            >
                              {name}
                            </Text>
                            <Flex alignItems="center">
                              {categoryOpen ? <NavClose /> : <NavOpen />}
                            </Flex>
                          </Flex>
                          {categoryOpen && (
                            <Flex flexDirection="column" ml={'10%'}>
                              {items &&
                                items.map(
                                  ({ text, url, page, _type, _key }) => {
                                    switch (_type) {
                                      case 'internalLink':
                                        return (
                                          <MobileNavInternalLink
                                            text={text}
                                            key={_key}
                                            _key={_key}
                                            page={page}
                                            currentPath={currentPath}
                                            zIndex={zIndex}
                                          />
                                        );
                                      case 'externalLink':
                                        return (
                                          <MobileNavExternalLink
                                            text={text}
                                            key={_key}
                                            _key={_key}
                                            url={url}
                                            currentPath={currentPath}
                                            zIndex={zIndex}
                                          />
                                        );
                                      default:
                                        return null;
                                    }
                                  },
                                )}
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                    );
                  default:
                    return null;
                }
              },
            )}
          </Flex>
        </Wrapper>
      </ReactModal>
    </>
  );
}

function NavbarCTAButton({ navbarStyle }) {
  const location = useLocation();
  let variant: string;
  let isLegacy: boolean;
  switch (navbarStyle) {
    case NavbarStyle.Primary:
      variant = 'navCTA';
      break;
    case NavbarStyle.Neon:
      variant = 'neon';
      isLegacy = false;
      break;
    case NavbarStyle.Light:
    case NavbarStyle.Transparent:
    case NavbarStyle.Dark:
    case NavbarStyle.Footer:
    case NavbarStyle.Dropshadow:
      variant = 'navCTAPrimary';
      break;

    // outline not used but leaving this
    // here as documentation
    // variant = 'outline';
    // break;
  }
  return (
    <>
      <NavCTAButton
        isLegacy={isLegacy}
        variant={variant}
        onClick={() => {
          if (isIOS) {
            open(APP_STORE_ID);
          } else if (isAndroid) {
            open(APP_DOWNLOAD_ANDROID);
          } else {
            isLegacy
              ? smartNav(location, eligibilityEmailPath)
              : open(APP_STORE_ID);
          }
        }}
      >
        Download
      </NavCTAButton>
    </>
  );
}

export function NavInternalLink({ variant, to, display, text, sx, color }) {
  return (
    <InternalLink
      variant={variant}
      to={to}
      display={display}
      sx={{
        fontFamily: variant === 'intNavLink' ? 'heading2' : 'body',
        fontSize: ['13px', '13px', '18px'],
        textDecoration: 'inherit',
        color: color ? color : 'inherit',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        padding: ['14px 16px', '14px 16px', '8px 16px'],
        ':hover': {
          color: colors.pale300,
        },
        ...sx,
      }}
      activeStyle={{
        fontWeight: 'bold',
      }}
    >
      {text}
    </InternalLink>
  );
}

export const MobileNavInternalLink = ({
  _key,
  page,
  currentPath,
  zIndex,
  text,
}) => {
  return (
    <Flex key={_key} my={['5px', '5px', '15px']}>
      <Box
        width={3}
        height={50}
        bg={colors.neon}
        sx={{
          visibility: currentPath.startsWith(page.slug.current)
            ? 'visible'
            : 'hidden',
          zIndex,
        }}
      />

      <NavInternalLink
        variant={'intNavLink'}
        text={text}
        key={_key}
        to={page.slug.current}
        display="block"
        color={
          currentPath.startsWith(page.slug.current) ? colors.neon : colors.white
        }
        sx={{
          zIndex,
          fontSize: ['22px', '22px', '32px'],
        }}
      />
    </Flex>
  );
};

export const MobileNavExternalLink = ({
  _key,
  currentPath,
  url,
  zIndex,
  text,
}) => {
  return (
    <Flex key={_key} my={['5px', '5px', '15px']}>
      <Box
        width={3}
        height={50}
        bg={colors.neon}
        sx={{
          visibility: currentPath.startsWith(url) ? 'visible' : 'hidden',
          zIndex,
        }}
      />
      <NavExternalLink
        variant={'intNavLink'}
        text={text}
        key={_key}
        to={url}
        display="block"
        color={currentPath.startsWith(url) ? colors.neon : colors.white}
        sx={{
          zIndex,
          fontSize: ['22px', '22px', '32px'],
        }}
      />
    </Flex>
  );
};

export function NavExternalLink({ variant, to, display, text, sx, color }) {
  return (
    <ExternalLink
      variant={variant}
      to={to}
      display={display}
      sx={{
        fontFamily: variant === 'intNavLink' ? 'heading2' : 'body',
        fontSize: ['13px', '13px', '18px'],
        textDecoration: 'inherit',
        color: color ? color : 'inherit',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        padding: ['14px 16px', '14px 16px', '8px 16px'],
        ':hover': {
          color: colors.pale300,
        },
        ...sx,
      }}
      activeStyle={{
        fontWeight: 'bold',
      }}
    >
      {text}
    </ExternalLink>
  );
}

export function BoostCardBanner() {
  return (
    <>
      <Flex
        style={{
          minHeight: '70px',
          backgroundColor: colors.violet300,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        css={{
          background: 'linear-gradient(155.08deg, #4840BB 0%, #241D89 101.65%)',
        }}
      >
        <LightningBolt style={{ height: 34, width: 34, marginTop: 20 }} />
        <Text
          variant="h500Medium"
          style={{
            margin: 10,
            marginBottom: 10,
            marginTop: 0,
            color: 'white',
            textAlign: 'center',
          }}
        >
          First 10 users get $5,000 each! First 1,000 get a free metal card!
        </Text>
        <Text
          variant="h500Medium"
          onClick={() => {
            navigate('/boostcard#promo');
          }}
          style={{
            color: colors.yellow,
            marginTop: 0,
            marginBottom: 10,
            cursor: 'pointer',
          }}
        >
          Learn More ›
        </Text>
      </Flex>
    </>
  );
}

export default Navbar;
