import React, { useState } from 'react';
import { Flex, Heading, Text, Box } from 'rebass';
import FormInput from '../inputs/formInput';
import { useForm } from 'react-hook-form';
import { emailValidator } from '../validations';
import { FormButton } from '../button';
import Form from '../form';
import { trackCTA } from '../cta';
import { toast } from 'react-toastify';
import { visor } from '../../protos';
import clientrpc from '../../api/clientrpc';
import { serverErrorToast } from '../toaster';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { smartNav } from './utils';
import colors from '../themes/colors';

export const eligibilityEmailPath = 'eligibility/email';

function EmailForm() {
  const form = useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { handleSubmit, watch } = form;
  const value = watch('email');
  const flag = useFlags();
  const onSubmit = data => {
    trackCTA('NAV', data.email, async () => {
      if (flag.gridNowEnabled) {
        setSubmitting(true);
        const req = new visor.clientrpc.SendLeadDownloadLinkRequest();
        req.email = data.email;
        try {
          await clientrpc.sendLeadDownloadLink(req);
          toast.success('Email sent!');
        } catch {
          serverErrorToast();
        } finally {
          setSubmitting(false);
        }
      } else {
        smartNav(location, 'eligibility/questionnaire', {
          email: data.email,
        });
      }
    });
  };
  return (
    <Flex
      flexDirection={['column', 'column', 'column']}
      justifyContent={'center'}
    >
      <Box>
        <Flex flexDirection={'column'}>
          <Heading
            sx={{
              fontFamily: 'Poppins',
              fontStyle: 'bold',
              fontWeight: 500,
              fontSize: ['28px', '28px', '42px'],
              lineHeight: ['40px', '40px', '75px'],
              letterSpacing: ['0.2px', '0.2px', '-0.03em'],
              color: colors.white,
            }}
          >
            Get started for free.
          </Heading>
        </Flex>
      </Box>
      <Box>
        <Form onSubmit={handleSubmit(onSubmit)} sx={{ marginTop: 26, flex: 1 }}>
          <FormInput
            type={'email'}
            name={'email'}
            placeholder={'Email'}
            label={'Email'}
            form={form}
            validation={emailValidator}
          />
          <FormButton
            disabled={!value}
            bg={colors.violet300}
            hoverColor={colors.violet400}
            loading={submitting}
          >
            Submit
          </FormButton>
        </Form>
      </Box>
    </Flex>
  );
}

export default EmailForm;
