import React from 'react';
import { navigate } from '@reach/router';
import { Link as RebassLink } from 'rebass';

export const InternalLink = ({
  variant,
  to,
  display,
  sx,
  activeStyle,
  children,
}) => (
  <RebassLink
    variant={variant}
    display={display}
    sx={sx}
    activeStyle={activeStyle}
    onClick={() => {
      console.info(`navigating to ${to}`);
      navigate(to);
    }}
  >
    {children}
  </RebassLink>
);

export const ExternalLink = ({
  variant,
  to,
  display,
  sx,
  activeStyle,
  children,
}) => (
  <RebassLink
    href={to}
    rel="noopener"
    target="_blank"
    variant={variant}
    display={display}
    sx={sx}
    activeStyle={activeStyle}
  >
    {children}
  </RebassLink>
);
