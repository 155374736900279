import { visor } from '../protos';
import { Message, rpc } from 'protobufjs';
import * as uuid from 'uuid';
import * as firebase from 'firebase/app';
import { auth, firestore } from './fireClient';

export class TwirpError extends Error {
  public code;
  public message;
  public meta;
  constructor(code, message, meta) {
    super();
    this.code = code;
    this.message = message;
    this.meta = meta;
  }
}

class RPCClient {
  private serviceName: string;
  private auth: firebase.auth.Auth;
  private firestore: firebase.firestore.Firestore;
  constructor(
    serviceName: string,
    auth: firebase.auth.Auth,
    firestore: firebase.firestore.Firestore,
  ) {
    this.serviceName = serviceName;
    this.auth = auth;
    this.firestore = firestore;
  }
  public serviceHandler<Req extends Message<Req>, Res extends Message<Res>>(
    method: rpc.ServiceMethod<Req, Res>,
    protoData: Uint8Array,
    cb: (error: Error, protoData: Uint8Array) => void,
  ) {
    this.do(method.name, protoData)
      .then(res => {
        cb(null, res);
      })
      .catch(err => {
        cb(err, null);
      });
  }
  private async do(endpoint: string, request: Uint8Array) {
    const target = `/${this.serviceName}/${endpoint}`;
    const token = await this.token();
    const res = await fetch(target, {
      method: 'POST',
      body: request,
      headers: {
        'Content-Type': 'application/protobuf',
        Accept: 'application/protobuf',
        'X-Correlation-Token': uuid.v1(),
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errorRes: any;
      try {
        const json = await res.json();
        errorRes = json;
      } catch (e) {
        throw new Error(
          `received error code but failed to parse error json: ${e}`,
        );
      }
      throw new TwirpError(errorRes.code, errorRes.msg, errorRes.meta);
    }
    const arrayBuffer = await res.arrayBuffer();
    return new Uint8Array(arrayBuffer);
  }
  private async token(): Promise<string> {
    if (this.auth.currentUser === null) {
      const anon = await this.auth.signInAnonymously();
      return await anon.user.getIdToken();
    }

    return await this.auth.currentUser.getIdToken();
  }
}

const defaultClient = new RPCClient(
  'visor.clientrpc.ClientRPCService',
  auth,
  firestore,
);

export function signInWithCustomToken(token: string) {
  return auth.signInWithCustomToken(token);
}

export default visor.clientrpc.ClientRPCService.create(
  defaultClient.serviceHandler.bind(defaultClient),
);
