import '../styles/carousel.css';
import '../styles/layout.css';

import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'emotion-theming';
import { Toaster } from './toaster';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ErrorBoundary from './errorBoundary';
import Navbar, { NavbarStyle } from './navbar';
import theme from './theme';
import { NAVBAR_HEIGHT } from './utils';

const win = typeof window !== 'undefined' && window;

export function DocumentHeader({ title }: { title: string }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;1,400;1,500&display=swap"
        rel="stylesheet"
      />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>{`${data.site.siteMetadata.title} - ${title}`}</title>
    </Helmet>
  );
}

const Layout = ({ children, page }) => {
  const { title, menu } = page || {};
  const [fixedNavHidden, setFixedNavHidden] = useState(true);

  useEffect(() => {
    let prevScrollTop = win.document.documentElement.scrollTop;
    if (win !== undefined && page.isLegacy) {
      const navbarTriggerHeight = NAVBAR_HEIGHT;
      const onScroll = e => {
        // Show nav bar when scrolling down below mark
        const st = window.pageYOffset || document.documentElement.scrollTop;
        const isScrollingUp = st < prevScrollTop;
        if (e.target.documentElement.scrollTop > navbarTriggerHeight) {
          setFixedNavHidden(false);
        } else {
          setFixedNavHidden(true);
        }
      };
      win.addEventListener('scroll', onScroll);

      return () => win.removeEventListener('scroll', onScroll);
    }
  }, []);

  let navbarStyle: NavbarStyle;
  const slug = page?.slug?.current;
  if (slug === '/about/' || slug === '/payboost/') {
    navbarStyle = NavbarStyle.Dark;
  } else if (slug === '/') {
    if (page.isLegacy) {
      navbarStyle = NavbarStyle.Primary;
    } else {
      navbarStyle = NavbarStyle.Neon;
    }
  } else if (slug === '/r/') {
    navbarStyle = NavbarStyle.Primary;
  } else if (
    slug === '/help/' ||
    slug === '/terms/' ||
    slug === '/terms/tos/' ||
    slug === '/termsv2/' ||
    slug === '/terms/rewards/' ||
    slug === '/privacy/'
  ) {
    navbarStyle = NavbarStyle.Light;
  } else if (slug === '/pricing/') {
    navbarStyle = NavbarStyle.Footer;
  } else {
    navbarStyle = NavbarStyle.Primary;
  }

  const shouldDisplayNavbar = slug !== '/boostcard/';

  return (
    <>
      <DocumentHeader title={title} />
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          {/* {navbarStyle === NavbarStyle.BoostCardWaitlist && <BoostCardBanner />} */}
          {shouldDisplayNavbar && page.isLegacy ? (
            <>
              <Navbar
                navbarStyle={navbarStyle}
                ctaStyle={page?.navbarCTA}
                menu={menu}
                currentPath={page && page.slug.current}
                fixed={false}
                hidden={false}
                isLegacy={true}
              />
              <Navbar
                navbarStyle={NavbarStyle.Light}
                ctaStyle={page?.navbarCTA}
                menu={menu}
                currentPath={page && page.slug.current}
                fixed={true}
                hidden={fixedNavHidden}
                isLegacy={true}
              />
            </>
          ) : (
            <>
              <Navbar
                navbarStyle={navbarStyle}
                ctaStyle="UPDATED_LANDING_PAGE"
                menu={menu}
                currentPath={page && page.slug.current}
                fixed={true}
                hidden={false}
                isLegacy={false}
              />
            </>
          )}

          {children}
          <Toaster />
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
