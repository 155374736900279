import { Flex, Box } from 'rebass';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import { useLocation } from '@reach/router';
import React from 'react';
import ReactModal from 'react-modal';

import { wrapperWidth } from './wrapper';
import CloseIcon from '../assets/svgs/Close.svg';
import Divider from './divider';
import GridLogo from '../assets/svgs/GridLogo.svg';
import colors from './themes/colors';

const win = typeof window !== 'undefined' ? window : undefined;

export function SimpleNavbar({ location }) {
  return (
    <>
      <Flex
        as={'nav'}
        height={70}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <GridLogo
          onClick={() => {
            // Close any open toasts.
            toast.dismiss();

            navigate('/', {
              state: { overlay: { visible: false, path: null } },
            });
          }}
        />
        <CloseIcon
          onClick={() => {
            // Close any open toasts.
            toast.dismiss();

            unlockScroll();
            if (location && location.pathname !== '/eligibility/email/') {
              // if overlay
              navigate(location.pathname, {
                replace: true,
                state: { overlay: { visible: false, path: null } },
              });
            } else {
              window.history.back();
            }
          }}
        />
      </Flex>
    </>
  );
}

const Overlay = ({ children, visible, location }) => {
  return (
    <ReactModal
      closeTimeoutMS={250}
      style={{
        content: {
          height: '100vh',
          width: '100vw',
          top: 0,
          left: 0,
          padding: 0,
          border: 'none',
          background: colors.footerBlack,
          outline: 'none',
        },
      }}
      isOpen={visible}
    >
      <Box margin={'auto'} width={wrapperWidth}>
        <SimpleNavbar location={location} />
        <Box py={'7vh'}>{children}</Box>
      </Box>
    </ReactModal>
  );
};

export const OverlayRoute = ({ path, children }) => {
  const location = useLocation();
  const overlayState =
    (location && location.state && location.state.overlay) || {};
  const visible = overlayState.visible && overlayState.path === path;
  if (visible) {
    lockScroll();
  }
  if (!overlayState.visible) {
    unlockScroll();
  }
  return (
    <Overlay location={location} visible={visible}>
      {children}
    </Overlay>
  );
};

export const openOverlay = (location, path, additionalData = {}) => {
  // Close any open toasts.
  toast.dismiss();

  navigate(location.pathname, {
    replace: true,
    state: {
      overlay: { visible: true, path: path, data: { ...additionalData } },
    },
  });
};

export const closeOverlay = location => {
  // Close any open toasts.
  toast.dismiss();

  navigate(location.pathname, {
    replace: true,
    state: { overlay: { visible: false, path: null } },
  });
};

export const lockScroll = () => {
  if (win !== undefined) {
    win.document.documentElement.style.overflow = 'hidden';
  }
};

export const unlockScroll = () => {
  if (win !== undefined) {
    win.document.documentElement.style.overflow = '';
  }
};

export default Overlay;
