import React, { ReactNode } from 'react';
import { Flex } from 'rebass';

interface FormProps {
  onSubmit: any;
  children: ReactNode | ReactNode[];
  sx?: any;
}

const Form = (props: FormProps) => {
  return (
    <Flex
      as={'form'}
      mt={26}
      onSubmit={props.onSubmit}
      sx={props.sx}
      disabled={true}
      flexDirection="row"
    >
      {props.children}
    </Flex>
  );
};

export default Form;
