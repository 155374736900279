import React from 'react';
import { Box, Flex, Text } from 'rebass';
// @ts-ignore
import ErrorSymbol from '../../assets/svgs/Error.svg';

const FieldError = ({ message }) => {
  return (
    <Flex
      mt={'8px'}
      mb={'10px'}
      alignItems={'center'}
      sx={{ color: 'red300', visibility: message ? 'visible' : 'hidden' }}
    >
      <Box minHeight={18} minWidth={18}>
        <ErrorSymbol />
      </Box>
      <Text ml={'4px'} variant={'h300Regular'} sx={{ lineHeight: '18px' }}>
        {message}
      </Text>
    </Flex>
  );
};

export const FieldMessage = ({ message, isError }) => {
  return (
    <Flex
      mt={'8px'}
      mb={'10px'}
      alignItems={'center'}
      sx={{
        color: isError ? 'red300' : 'teal300',
        visibility: message ? 'visible' : 'hidden',
      }}
    >
      <Box
        sx={{
          visibility: isError && message ? 'visible' : 'hidden',
        }}
      >
        <ErrorSymbol />
      </Box>
      <Text variant={'h300Regular'} ml={'4px'} sx={{ lineHeight: '18px' }}>
        {message}
      </Text>
    </Flex>
  );
};

export default FieldError;
