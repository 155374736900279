import React from 'react';
import { Flex } from 'rebass';
import { Input } from '@rebass/forms';
import { RoundedAccentButton } from '../button';
import Form from '../form';
import FieldError from './fieldError';

const InputButton = ({
  placeholder,
  type,
  name,
  onSubmit,
  validator,
  form,
  loading,
}: Input) => {
  const { errors, handleSubmit, register } = form;
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Flex mt={3} bg="white" p={2} sx={{ borderRadius: '72px' }}>
        <Input
          type={type}
          name={name}
          color={'black'}
          flex="0 1 auto"
          p={2}
          m={0}
          ref={register(validator)}
          placeholder={placeholder}
          sx={{
            outline: 'none',
            border: 'none',
          }}
        />
        <RoundedAccentButton
          type={'submit'}
          flex="1 0 auto"
          py={2}
          px={3}
          m={0}
          loading={loading}
        >
          {'Download'}
        </RoundedAccentButton>
      </Flex>
      <FieldError message={errors[name]?.message} />
    </Form>
  );
};

export default InputButton;
