export const track = (
  event: string,
  properties?: Object,
  callback?: () => void,
) => {
  const win = typeof window !== 'undefined' && window;
  if (win !== undefined && win.analytics) {
    win.analytics.track(event, properties || {}, {}, callback);
  } else {
    setTimeout(() => {
      callback();
    }, 0);
  }
};
