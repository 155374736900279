import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { openOverlay } from '../overlay';
import { navigate } from '@reach/router';

export function smartNav(location: Location, path: string, data?: any) {
  if (isMobile) {
    // Close any open toasts.
    toast.dismiss();

    navigate(`/${path}`, {
      state: {
        overlay: { data: { eligibility: data } },
      },
    });
  } else {
    openOverlay(location, path, { eligibility: data });
  }
}
