import React, { useState } from 'react';
import { Input } from '@rebass/forms';
import { useTheme } from 'emotion-theming';
import FieldError from './fieldError';
import { FormContextValues } from 'react-hook-form';

type FormInputProps = {
  name: string;
  label: string;
  placeholder: string;
  form: FormContextValues<any>;
  validation: any;
  disabled?: boolean;
} & Input;

const FormInput = ({
  name,
  label,
  placeholder,
  form,
  validation,
  disabled,
  type,
}: FormInputProps) => {
  const [value, setValue] = useState();
  const theme: any = useTheme();
  const { register, errors } = form;
  return (
    <>
      <Input
        type={type}
        name={name}
        ref={register(validation)}
        placeholder={placeholder}
        backgroundColor="white"
        onChange={e => {
          setValue(e.target.value);
        }}
        disabled={disabled}
        sx={{
          pl: 0,
          outline: 'none',
          paddingLeft: '6px',
          paddingRight: '6px',
          borderRadius: '6px',
          fontFamily: theme?.text.h500Regular.fontFamily,
          fontSize: theme?.text.h500Regular.fontSize,
          lineHeight: theme?.text.h500Regular.lineHeight,
          fontWeight: theme?.text.h500Regular.fontWeight,
          '::placeholder': {
            fontSize: '16px',
          },
        }}
      />
      <FieldError
        message={errors[name]?.message ?? errors[name]?.types?.message}
      />
    </>
  );
};

export default FormInput;
