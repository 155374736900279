import 'react-toastify/dist/ReactToastify.css';

import { ClassNames, css } from '@emotion/core';
import { ToastContainer, toast } from 'react-toastify';
import { transparentize } from 'polished';
import React from 'react';

import theme from './theme';

// Toaster integrates react-toastify with grid-web.
export function Toaster() {
  return (
    <ClassNames>
      {({ css, cx }) => (
        <ToastContainer
          position="bottom-center"
          autoClose={40000}
          closeButton={false}
          hideProgressBar={true}
          className={css({
            width: '50%',
            padding: 0,
            margin: 0,
            zIndex: 9999999,

            '@media (max-width: 768px)': {
              width: '100%',
              transform: 'unset',
            },
          })}
          toastClassName={css({
            fontFamily: theme.fonts.heading,
            fontWeight: 'normal',
            fontSize: '15pt',
            letterSpacing: theme.text.h100.letterSpacing,
            margin: 0,
            minHeight: 'auto',
            textAlign: 'center',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            color: theme.colors.white,
            backgroundColor: theme.colors.black,

            borderRadius: 0,

            '@media (min-width: 768px)': {
              borderRadius: '40px',
              padding: '5px, 13px',
            },

            '@media (max-width: 768px)': {
              padding: '20px',
            },

            '&.Toastify__toast--success': {
              color: theme.colors.white,
              backgroundColor: transparentize(0.1, theme.colors.teal300),
            },
            '&.Toastify__toast--info': {
              color: theme.colors.white,
              backgroundColor: transparentize(0.1, theme.colors.blue400),
            },
            '&.Toastify__toast--warning': {
              color: theme.colors.white,
              backgroundColor: transparentize(0.1, theme.colors.violet300),
            },
            '&.Toastify__toast--error': {
              color: theme.colors.white,
              backgroundColor: transparentize(0.1, theme.colors.red300),
            },
          })}
          bodyClassName={css({
            margin: 0,
            padding: 0,
          })}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          limit={1}
        />
      )}
    </ClassNames>
  );
}

export const serverErrorToast = () => {
  toast.error(
    'Oops! Something with our server went wrong, try hitting ‘Submit’ again.',
  );
};
