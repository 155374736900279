import React from 'react';
import ErrorPage from '../pages/50x';

interface ErrorBoundaryState {
  error: Error | null;
}

interface ErrorBoundaryProps {}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  componentDidCatch(error, errorInfo) {
    // Log errors here
  }

  render() {
    if (this.state.error) {
      // Render fallback UI
      return <ErrorPage error={this.state.error} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
