import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const json = process.env.GATSBY_VISOR_GETGRIDAPP_FIREBASE;

const firebaseConfig = json ? JSON.parse(json) : {};

const app = firebase.initializeApp(firebaseConfig);

export const firestore = app.firestore();
export const auth = app.auth();

if (!auth.currentUser) {
  auth.signInAnonymously();
}

export default app;
