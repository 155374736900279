import React from 'react';
import { Box, Link, Flex, Image } from 'rebass';
// @ts-ignore
import AppStoreDownload from '../assets/svgs/AppStore.svg';
// @ts-ignore
import AppStore from '../assets/images/AppStore.png';
// @ts-ignore
import GooglePlay from '../assets/images/GooglePlay.png';
// @ts-ignore
import GooglePlayDark from '../assets/images/GooglePlayDark.png';
// @ts-ignore
import GooglePlayIconRound from '../assets/svgs/GooglePlayIconRound.svg';
// @ts-ignore
import AppleAppStoreIconRound from '../assets/svgs/AppleAppStoreIconRound.svg';
import AppleAppStoreDarkIconRound from '../assets/svgs/AppleAppStoreDarkIconRound.svg';
import GooglePlayDarkIconRound from '../assets/svgs/GooglePlayDarkIconRound.svg';
import { default as analytics } from '@segment/analytics.js-core';
import {
  SMS_LEAD_GEN_WITH_APP_STORE_DOWNLOAD_BUTTONS,
  APP_STORE_DOWNLOAD_BUTTONS_ONLY,
  DYNAMIC_DOWNLOAD_CTA_WITH_RATING,
  DYNAMIC_DOWNLOAD_CTA_WITHOUT_RATING,
} from './cta';
import { TrackingLink } from './trackingLink';
import { useLocation } from '@reach/router';
import { AndroidView, IOSView, BrowserView } from 'react-device-detect';
import { PaddedRoundedButton } from './button';
import { navigate } from '@reach/router';

declare global {
  interface Window {
    analytics?: typeof analytics;
  }
}
export const APP_STORE_ID =
  'https://apps.apple.com/us/app/grid-paycheck-boost/id1473180914';

export const APP_DOWNLOAD_ANDROID =
  'https://play.google.com/store/apps/details?id=com.hatfield.grid';

export const AppDownloadButtons = (props: {
  ctaStyle: string;
  context: 'HERO' | 'FOOTER' | 'NAVBAR';
}) => {
  switch (props.ctaStyle) {
    case SMS_LEAD_GEN_WITH_APP_STORE_DOWNLOAD_BUTTONS:
      return (
        <Box>
          <IOSView>
            <AppleAppStoreButton context={props.context} />
          </IOSView>
          <AndroidView>
            <GooglePlayButton context={props.context} />
          </AndroidView>
          <BrowserView>
            <PaddedRoundedButton
              variant="purple"
              onClick={() => {
                navigate('/eligibility/email/');
              }}
              bg={'teal300'}
              hoverColor={'teal400'}
            >
              Download
            </PaddedRoundedButton>
          </BrowserView>
        </Box>
      );
    case APP_STORE_DOWNLOAD_BUTTONS_ONLY:
      return <AppleAppStoreButton context={props.context} />;
    case DYNAMIC_DOWNLOAD_CTA_WITH_RATING:
    case DYNAMIC_DOWNLOAD_CTA_WITHOUT_RATING:
      return (
        <>
          <IOSView>
            <AppleAppStoreButton context={props.context} />
          </IOSView>
          <AndroidView>
            <GooglePlayButton context={props.context} />
          </AndroidView>
        </>
      );
    default:
      return null;
  }
};

export const AppleAppStoreButton = (props: {
  context: 'HERO' | 'FOOTER' | 'NAVBAR' | 'OVERLAY';
  component?: Link;
  height?: number[];
}) => {
  return (
    <TrackingLink
      Component={props.component}
      event="Landing Page User Tapped iOS Download Button"
      href={APP_STORE_ID}
      target={'_blank'}
      rel={'noopener noreferrer'}
      eventProperties={{
        location: props.context,
      }}
      sx={{
        margin: 0,
        padding: 0,
        backgroundColor: 'unset',
        ':hover': {
          backgroundColor: 'unset',
        },
      }}
    >
      <Image
        height={props.height || 40}
        src={AppStore}
        sx={{
          cursor: 'pointer',
          objectFit: 'contain',
        }}
      />
    </TrackingLink>
  );
};

export const GooglePlayButton = (props: {
  context: 'HERO' | 'FOOTER' | 'NAVBAR';
  component?: Link;
  height?: number[];
}) => {
  return (
    <TrackingLink
      event="Landing Page User Tapped Android Download Button"
      eventProperties={{
        location: props.context,
      }}
      target={props.component === Link && '_blank'}
      href={APP_DOWNLOAD_ANDROID}
      Component={props.component}
    >
      <Image
        src={GooglePlayDark}
        height={props.height || 40}
        sx={{
          cursor: 'pointer',
          objectFit: 'contain',
        }}
      />
    </TrackingLink>
  );
};

export const AppleAndGoogleButtons = (props: {
  context: 'HERO' | 'FOOTER' | 'NAVBAR';
}) => {
  return (
    <Flex flexDirection={'row'} justifyContent="flex-start" my={4}>
      <Box mr={8}>
        <AppleAppStoreButton context={props.context} />
      </Box>
      <Box>
        <GooglePlayButton context={props.context} />
      </Box>
    </Flex>
  );
};

export const AppleAppStoreIconRoundButton = (props: {
  context: 'HERO' | 'FOOTER' | 'NAVBAR';
  isLegacy?: true;
}) => {
  return (
    <TrackingLink
      event="Landing Page User Tapped iOS Download Button"
      href={APP_STORE_ID}
      target={'_blank'}
      rel={'noopener noreferrer'}
      eventProperties={{
        location: props.context,
      }}
    >
      {props.isLegacy ? (
        <AppleAppStoreIconRound sx={{ cursor: 'pointer' }} />
      ) : (
        <AppleAppStoreDarkIconRound sx={{ cursor: 'pointer' }} />
      )}
    </TrackingLink>
  );
};

export const GooglePlayIconRoundButton = (props: {
  context: 'HERO' | 'FOOTER' | 'NAVBAR';
  isLegacy?: true;
}) => {
  return (
    <TrackingLink
      event="Landing Page User Tapped Android Download Button"
      eventProperties={{
        location: props.context,
      }}
      href={APP_DOWNLOAD_ANDROID}
    >
      <Box sx={{ cursor: 'pointer' }}>
        {props.isLegacy ? <GooglePlayIconRound /> : <GooglePlayDarkIconRound />}
      </Box>
    </TrackingLink>
  );
};

export const DownloadButtons = () => {
  return (
    <Flex
      flexDirection={'row'}
      mt="5%"
      justifyContent={['space-around', 'space-around', 'flex-start']}
    >
      <Flex mr={['', '', '3%']} alignItems="center">
        <AppleAppStoreButton
          height={[47.5, 55, 60]}
          context={'HERO'}
          component={Link}
        />
      </Flex>
      <Flex alignItems="center">
        <GooglePlayButton
          height={[53.5, 65, 67.5]}
          context={'HERO'}
          component={Link}
        />
      </Flex>
    </Flex>
  );
};
