import React, { useState } from 'react';
import { Button } from 'rebass';
import colors from './themes/colors';

type ButtonBaseProps = {
  loading: boolean;
  hoverColor: 'string';
  activeColor: 'string';
  onClick: () => Promise<any> | void;
} & Button;

const Spinner = () => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="circle-notch"
      className="svg-inline--fa fa-circle-notch fa-w-16 spin-icon"
      height={24}
      role="img"
      style={{
        verticalAlign: 'middle',
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"
      />
    </svg>
  </>
);

export const ButtonBase = (props: ButtonBaseProps & Button) => {
  const { disabled, hoverColor, activeColor, sx, bg, loading } = props;
  const [waiting, setWaiting] = useState(false);
  const buttonDisabled = disabled || loading || waiting;

  const onClickHandler = props.onClick
    ? () => {
        const result = props.onClick();
        if (result) {
          setWaiting(true);
          result.finally(() => {
            setWaiting(false);
          });
        }
      }
    : null;

  return (
    <Button
      {...props}
      onClick={onClickHandler}
      bg={buttonDisabled ? hoverColor : bg}
      disabled={buttonDisabled}
      minWidth={'150px'}
      sx={{
        cursor: buttonDisabled ? 'default' : 'pointer',
        outline: 'none',
        ':hover': buttonDisabled
          ? {}
          : {
              bg: hoverColor,
            },
        ':active': buttonDisabled
          ? {}
          : {
              bg: activeColor,
            },
        fontWeight: 500,
        ...sx,
      }}
    >
      <div
        css={{
          position: 'relative',
        }}
      >
        <div
          css={{
            visibility: loading || waiting ? 'hidden' : 'visible',
          }}
        >
          {props.children}
        </div>
        <div
          css={{
            position: 'absolute',
            top: 0,
            width: '100%',
            display: loading || waiting ? 'block' : 'none',
          }}
        >
          <Spinner />
        </div>
      </div>
    </Button>
  );
};

export const RoundedAccentButton = (props: ButtonBaseProps) => {
  const { disabled, children, loading, onClick } = props;
  return (
    <ButtonBase
      onClick={onClick}
      variant="accent"
      disabled={disabled}
      loading={loading}
      sx={{
        borderRadius: 72,
        whiteSpace: 'nowrap',
        fontWeight: 500,
      }}
      {...props}
    >
      {children}
    </ButtonBase>
  );
};

export const PrimaryButton = (props: ButtonBaseProps) => {
  const {
    disabled,
    children,
    onClick,
    loading,
    bg,
    hoverColor,
    activeColor,
    sx = {},
    className,
    px = '40px',
    my = undefined,
  } = props;
  return (
    <ButtonBase
      className={className}
      onClick={onClick}
      loading={loading}
      type={'submit'}
      bg={bg === 'undefined' ? 'teal300' : bg}
      activeColor={activeColor === 'undefined' ? 'teal200' : activeColor}
      hoverColor={hoverColor === 'undefined' ? 'teal200' : hoverColor}
      disabled={disabled}
      px={px}
      my={my}
      sx={{
        fontFamily: 'Poppins',
        fontSize: '16px',
        lineHeight: '28px',
        borderRadius: '72px',
        ...sx,
      }}
    >
      {children}
    </ButtonBase>
  );
};

export const PaddedRoundedButton = (props: ButtonBaseProps) => {
  const {
    disabled,
    children,
    onClick,
    loading,
    bg,
    activeColor,
    hoverColor,
    className,
    px,
  } = props;
  return (
    <ButtonBase
      onClick={onClick}
      loading={loading}
      type={'submit'}
      bg={bg ? bg : 'teal300'}
      activeColor={activeColor ? activeColor : 'teal400'}
      hoverColor={hoverColor ? hoverColor : 'teal200'}
      disabled={disabled}
      className={className}
      px={px || '40px'}
      sx={{
        fontFamily: 'Poppins',
        fontSize: '16px',
        lineHeight: '28px',
        borderRadius: '72px',
        height: '50px',
      }}
    >
      {children}
    </ButtonBase>
  );
};

export const FormButton = (props: ButtonBaseProps) => {
  const { disabled, children, loading } = props;
  return (
    <ButtonBase
      type={'submit'}
      bg={colors.violet300}
      activeColor={colors.violet400}
      hoverColor={colors.violet200}
      disabled={disabled}
      px={'40px'}
      loading={loading}
      sx={{
        fontFamily: 'Poppins',
        fontSize: '16px',
        lineHeight: '28px',
        borderRadius: '72px',
      }}
    >
      {children}
    </ButtonBase>
  );
};

export const NavCTAButton = (props: ButtonBaseProps) => {
  return (
    <Button
      variant={props.variant}
      onClick={props.onClick}
      sx={{
        whiteSpace: 'nowrap',
        paddingX: props.isLegacy === false ? 16 : 24,
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
};
