import { Flex, Text, Box, Image } from 'rebass';
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';

import {
  AppDownloadButtons,
  AppleAppStoreButton,
  APP_DOWNLOAD_ANDROID,
  APP_STORE_ID,
} from './appStoreButtons';
import { emailValidator } from './validations';
import InputButton from './inputs/inputButton';
import { track } from './analytics';
import { visor } from '../protos';
import clientrpc from '../api/clientrpc';
import { toast } from 'react-toastify';
import { serverErrorToast } from './toaster';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { smartNav } from './eligibility/utils';
import { isIOS, isAndroid, BrowserView, MobileView } from 'react-device-detect';
import { NavCTAButton, PaddedRoundedButton } from './button';
// @ts-ignore
import Stars4_5 from '../assets/svgs/Stars4_5.svg';
import { navigate } from '@reach/router';
import { eligibilityEmailPath } from './eligibility/email';

export const SMS_LEAD_GEN_WITH_APP_STORE_DOWNLOAD_BUTTONS =
  'SMS_LEAD_GEN_WITH_APP_STORE_DOWNLOAD_BUTTONS_V1';
export const APP_STORE_DOWNLOAD_BUTTONS_ONLY =
  'APP_STORE_DOWNLOAD_BUTTONS_ONLY_V1';
export const NO_CTA = 'NO_CTA';
export const EMAIL_LEADS = 'EMAIL_LEADS_V1';

export const DOWNLOAD_CTA_WITH_RATING = 'CTA_WITH_RATING';
export const DOWNLOAD_CTA = 'CTA_WITHOUT_RATING';
export const DYNAMIC_DOWNLOAD_CTA_WITH_RATING =
  'DYNAMIC_DOWNLOAD_CTA_WITH_RATING';
export const DYNAMIC_DOWNLOAD_CTA_WITHOUT_RATING =
  'DYNAMIC_DOWNLOAD_CTA_WITHOUT_RATING';

export const VIRAL_LOOPS_BOOST_CARD = 'VIRAL_LOOPS_BOOST_CARD';

export const trackCTA = (context, email, callback) => {
  track(
    'Landing Page User Submitted Email in CTA',
    { email, context },
    callback,
  );
};

const CTA = ({ ctaStyle, context, legal }) => {
  if (context === 'FOOTER') {
    switch (ctaStyle) {
      case SMS_LEAD_GEN_WITH_APP_STORE_DOWNLOAD_BUTTONS:
        const form = useForm();
        const { reset } = form;
        const [submitting, setSubmitting] = useState<boolean>(false);
        const flag = useFlags();
        return (
          <>
            <Box display={['none', 'none', 'block']}>
              <Flex flexDirection="column">
                <InputButton
                  type={'email'}
                  form={form}
                  name={'ctaEmail'}
                  loading={submitting}
                  onSubmit={data => {
                    if (flag.gridNowEnabled) {
                      trackCTA(context, data.ctaEmail, async () => {
                        setSubmitting(true);
                        const req = new visor.clientrpc.SendLeadDownloadLinkRequest();
                        req.email = data.ctaEmail;
                        try {
                          await clientrpc.sendLeadDownloadLink(req);
                          toast.success('Email sent!');
                        } catch {
                          serverErrorToast();
                        } finally {
                          setSubmitting(false);
                        }
                      });
                    } else {
                      smartNav(location, 'eligibility/questionnaire', {
                        email: data.ctaEmail,
                      });
                      reset();
                    }
                  }}
                  placeholder="Email"
                  validator={emailValidator}
                />
                <AppDownloadButtons ctaStyle={ctaStyle} context={context} />
                <Text variant="h100" color="white" mb={30}>
                  {legal}
                </Text>
              </Flex>
            </Box>
            <Box display={['block', 'block', 'none']}>
              <AppDownloadButtons ctaStyle={ctaStyle} context={context} />
            </Box>
          </>
        );
      case APP_STORE_DOWNLOAD_BUTTONS_ONLY:
        return <AppleAppStoreButton context={context} />;
      default:
        return null;
    }
  } else if (context === 'HERO') {
    const form = useForm();
    const { reset } = form;
    switch (ctaStyle) {
      case SMS_LEAD_GEN_WITH_APP_STORE_DOWNLOAD_BUTTONS:
        const [submitting, setSubmitting] = useState<boolean>(false);
        const flag = useFlags();
        return (
          <Flex key={ctaStyle} flexDirection="column">
            <Box>
              <InputButton
                type={'email'}
                form={form}
                name={'ctaEmail'}
                loading={submitting}
                onSubmit={data => {
                  trackCTA(context, data.ctaEmail, async () => {
                    if (flag.gridNowEnabled) {
                      setSubmitting(true);
                      const req = new visor.clientrpc.SendLeadDownloadLinkRequest();
                      req.email = data.ctaEmail;
                      try {
                        await clientrpc.sendLeadDownloadLink(req);
                        toast.success('Email sent!');
                      } catch {
                        serverErrorToast();
                      } finally {
                        setSubmitting(false);
                      }
                    } else {
                      smartNav(location, 'eligibility/questionnaire', {
                        email: data.ctaEmail,
                      });
                      reset();
                    }
                  });
                }}
                placeholder="Email"
                validator={emailValidator}
              />
            </Box>
            <AppDownloadButtons ctaStyle={ctaStyle} context={context} />
          </Flex>
        );
      case APP_STORE_DOWNLOAD_BUTTONS_ONLY:
        return <AppleAppStoreButton context={context} />;
      case DOWNLOAD_CTA:
        return (
          <PaddedRoundedButton
            variant="purple"
            onClick={() => navigate('/eligibility/email/')}
            bg={'violet300'}
            hoverColor={'violet400'}
          >
            Download
          </PaddedRoundedButton>
        );

      case DOWNLOAD_CTA_WITH_RATING:
        return (
          <Box>
            <PaddedRoundedButton
              variant="purple"
              onClick={() => {
                navigate('/eligibility/email/');
              }}
              bg={'violet300'}
              hoverColor={'violet400'}
            >
              Download
            </PaddedRoundedButton>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              pt={3}
            >
              <Stars4_5 />
              <Text
                variant="h100"
                color="white"
                opacity="70%"
                paddingLeft="5px"
              >
                App Store Rating
              </Text>
            </Box>
          </Box>
        );
      case DYNAMIC_DOWNLOAD_CTA_WITH_RATING:
        return (
          <Box textAlign="center">
            <BrowserView>
              <NavCTAButton
                variant="purple"
                onClick={() => {
                  if (isIOS) {
                    open(APP_STORE_ID);
                  } else if (isAndroid) {
                    open(APP_DOWNLOAD_ANDROID);
                  } else {
                    smartNav(location, eligibilityEmailPath);
                  }
                }}
              >
                Download
              </NavCTAButton>
            </BrowserView>
            <MobileView>
              <AppDownloadButtons ctaStyle={ctaStyle} context={context} />
            </MobileView>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              pt={3}
            >
              <Stars4_5 />
              <Text
                variant="h100"
                color="white"
                opacity="70%"
                paddingLeft="5px"
              >
                App Store Rating
              </Text>
            </Box>
          </Box>
        );
      case DYNAMIC_DOWNLOAD_CTA_WITHOUT_RATING:
        return <AppDownloadButtons ctaStyle={ctaStyle} context={context} />;
      case VIRAL_LOOPS_BOOST_CARD:
        return (
          <PaddedRoundedButton
            variant="purple"
            bg={'violet300'}
            hoverColor={'violet400'}
            className="vrlps-trigger"
            px={20}
          >
            Join the Waitlist
          </PaddedRoundedButton>
        );
      case NO_CTA:
        return null;
      case EMAIL_LEADS:
        return null;
      default:
        return null;
    }
  }
  if (ctaStyle) {
    console.warn(`Implement CTA style: ${ctaStyle}`);
  }
  return null;
};

export default CTA;
